/**
 * Range Servant - React Application
 *
 * @category   Application_Frontend
 * @package    range-servant-app
 * @author     Range Servant
 * @developer  Suman Barua <sumanbarua576@gmail.com>
 **/

import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import auth from "./views/auth/models/Auth";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/components/Login"));
//const Register = React.lazy(() => import('./views/pages/registration/registration'));
const Register = React.lazy(() =>
  import("./views/auth/components/Registration")
);
const ForgotPassword = React.lazy(() =>
  import("./views/auth/components/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/auth/components/ResetPassword")
);
const Signup = React.lazy(() => import("./views/auth/components/Signup"));
const TermsAndConditions = React.lazy(() =>
  import("./views/pages/terms-and-conditions/Rules")
);
const GdprPolicy = React.lazy(() =>
  import("./views/pages/gdpr-policy/gdprPolicy")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/reg/:id"
              name="Registration Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/regus/:id"
              name="Registration Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/reset-password"
              name="Reset Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/signup"
              name="User Signup Page"
              render={(props) => <Signup {...props} />}
            />
            <Route
              exact
              path="/terms-and-conditions"
              name="Terms and Conditions Page"
              render={(props) => <TermsAndConditions {...props} />}
            />
            <Route
              exact
              path="/gdpr-policy"
              name="GDPR Policy"
              render={(props) => <GdprPolicy {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
