/**
 * Range Servant - React Application
 *
 * @category   Application_Frontend
 * @package    range-servant-app
 * @author     Range Servant
 * @developer  Suman Barua <sumanbarua576@gmail.com>
 **/

import jwtDecode from "jwt-decode";
import http from "./../../../services/http-service";

// api endpoint
const apiUrl = process.env.REACT_APP_API_ENDPOINT;

const tokenKey = "token";
const tokenData = "user";
const Sitedata = "Sitedata";
const loginEndPoint = apiUrl + "/login";
let resetPasswordEndPoint = apiUrl + "/user/resetPassword";
const forgotPasswordEndPoint = apiUrl + "/user/forgotPassword";
const registrationEmailEndPoint= apiUrl+"/registerEmail"

// set json web token
http.setJwt(getJwt());

export async function login(email, password) {
  try{
    const data = {userId: email, password};
    const { 
      data: { 
        jwt = null, 
        user = {},
      } 
    } = await http.post(loginEndPoint, data);
    
    // store the json web token to local-storage of browser
    // and redirect the user to the home page
    localStorage.setItem(tokenKey, jwt);
    localStorage.setItem(tokenData, JSON.stringify(user));

    // return jwt
    return jwt;
  }catch(error){
    // show error
  }
}

export async function forgotPassword(email) {
  try{
    const data = { email: email };
    return await http.post(forgotPasswordEndPoint, data);
  }catch(error){
    // show error
  }
}

export async function resetPassword(values) {
  try{
    const data = {       
      password1: values.password || "",
      password2: values.confirm_password || "",
    };
    const token = values.token;
    const email = encodeURIComponent(values.email);
    const fullEndPoint = `${resetPasswordEndPoint}?email=${email}&token=${token}`;
    
    return await http.post(fullEndPoint, data);
  }catch(error){
    // show error
  }
}

export function loginWithJwt(jwt, user) {
  // store the jwt into the local-storage
  localStorage.setItem(tokenKey, jwt);
  localStorage.setItem(tokenData, JSON.stringify(user));
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(tokenData);
  localStorage.removeItem(Sitedata);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    let user = localStorage.getItem(tokenData);
    user = JSON.parse(user);    
    const decodedJwt = jwtDecode(jwt);

    // if userId is not valid
    if(user.userId !== decodedJwt.userId) {
      localStorage.removeItem(tokenKey);
      localStorage.removeItem(tokenData);
      localStorage.removeItem(Sitedata);
      return null;
    }

    // return valid user object
    return user;    
  } catch (ex) {
    return null;
  }
}

export function isSelf(userId) {
  const user = getCurrentUser();
  if (userId && user && user.userId === userId) return true;
  return false;
}

export function isAdmin() {
  const user = getCurrentUser();
  if (user && user.userType === "admin") return true;
  return false;
}

export function isDistributor() {
  const user = getCurrentUser();
  if (user && user.userType === "distributor") return true;
  return false;
}

export function isCustomer() {
  const user = getCurrentUser();
  if (user && user.userType === "customer") return true;
  return false;
}

export function isGreenKeeper() {
  const user = getCurrentUser();
  if (user && user.userType === "greenkeeper") return true;
  return false;
}

export function getIdentity() {
  const user = getCurrentUser();
  if (user && user.userId) return user.userId;
  return null;
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export function isAllowed(access = [], item = null) {
  const user = getCurrentUser();
  
  if (user && access && access.length) {
    if (user.userType === "admin" && access.includes("admin")) return true;
    if (user.userType === "distributor" && access.includes("distributor")) {
      // check blocked list
      if([].includes(item)) return false;

      return true;
    }
    if (user.userType === "customer" && access.includes("customer")) {
      // check blocked list
      if(['sites'].includes(item)) return false;

      return true;
    }
    if (user.userType === "greenkeeper" && access.includes("greenkeeper")) {
      // check blocked list
      if(['sites'].includes(item)) return false;

      return true;
    }
  }

  return false;
}

export async function registerEmail(rsConnectValue,userName,email,companyName,fullAddress,phonenumberRegistration,registrationType) {
  try{
  const data = { rsconnectId: rsConnectValue,
    name: userName,
    email:email,
    companyName: companyName,
    fullAddress: fullAddress,
    phoneNo:phonenumberRegistration,
    type:registrationType
   };
  return  await http.post(registrationEmailEndPoint,data);
  }catch(error){
    return null;
  }
}

export default {
  login,
  registerEmail,
  forgotPassword,
  resetPassword,
  loginWithJwt,
  logout,
  getCurrentUser,
  isSelf,
  isAdmin,
  isDistributor,
  isCustomer,
  isGreenKeeper,
  getIdentity,
  getJwt,
  isAllowed,
};
