/**
 * Range Servant - React Application
 *
 * @category   Application_Frontend
 * @package    range-servant-app
 * @author     Range Servant
 * @developer  Suman Barua <sumanbarua576@gmail.com>
 **/

import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store