/**
 * Range Servant - React Application
 *
 * @category   Application_Frontend
 * @package    range-servant-app
 * @author     Range Servant
 * @developer  Suman Barua <sumanbarua576@gmail.com>
 **/

import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";

import "react-toastify/dist/ReactToastify.css";

React.icons = icons;

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URL,
  siteId: process.env.REACT_APP_MATOMO_SITE_ID,
});

ReactDOM.render(
  <MatomoProvider value={instance}>
    <Provider store={store}>
      <ToastContainer />
      <App />
    </Provider>
  </MatomoProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
