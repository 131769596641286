/**
 * Range Servant - React Application
 *
 * @category   Application_Frontend
 * @package    range-servant-app
 * @author     Range Servant
 * @developer  Suman Barua <sumanbarua576@gmail.com>
 **/

function init() {
  // Error Loging: Using Sentry-Browser SDK
  // Error will be found on sentry.io after login
}

function log(error) {
  console.log(error);
}

export default {
  init,
  log,
};
